import React, { useEffect, useState } from 'react';
import ky from 'ky';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShieldAlt } from '@fortawesome/free-solid-svg-icons';


function App() {
  const [backupData, setBackupData] = useState<any[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isDarkMode, setIsDarkMode] = useState(() => {
    const savedTheme = localStorage.getItem('theme');
    return savedTheme === 'dark';
  });

  useEffect(() => {
    getBackupData();
  }, [])

  useEffect(() => {
    localStorage.setItem('theme', isDarkMode ? 'dark' : 'light');
  }, [isDarkMode]);

  const getBackupData = async () => {
    const res = await ky.get('https://api.resurrect.network/backup-data').json<any[]>();
    setBackupData(res);
  }

  const filteredData = backupData.filter(item =>
    Object.values(item).some((value: unknown) =>
      String(value).toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  const toggleTheme = () => {
    setIsDarkMode(!isDarkMode);
  };

  return (
    <div className={`App min-h-screen ${isDarkMode ? 'bg-gray-900 text-white' : 'bg-gray-100 text-black'}`}>
      <header className={`${isDarkMode ? 'bg-blue-800' : 'bg-blue-600'} text-white p-4 flex justify-between items-center`}>
        <h1 className="text-3xl font-bold text-center pixel-font">RESURRECT</h1>
        <button
          onClick={toggleTheme}
          className={`px-2 py-1 rounded ${isDarkMode ? 'bg-gray-300 text-black' : 'bg-gray-800 text-white'}`}
        >
          {isDarkMode ? '☀️' : '🌙'}
        </button>
      </header>
      <main className="container mx-auto p-4">
        <div className="flex justify-between items-center mb-4">
          <input
            type="text"
            placeholder="Search..."
            className={`w-full p-2 mr-2 border rounded ${isDarkMode ? 'bg-gray-800 text-white' : 'bg-white text-black'}`}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
          {filteredData.map((item, index) => (

            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3, delay: index * 0.1 }}
              className={`rounded-lg shadow-md overflow-hidden transition-all duration-300 hover:shadow-lg hover:-translate-y-1 ${isDarkMode ? 'bg-gray-800' : 'bg-white'}`}
            >
              <div className={`bg-gradient-to-r ${isDarkMode ? 'from-blue-700 to-blue-800' : 'from-blue-500 to-blue-600'} text-white p-3`}>
                <h2 className="text-xl font-semibold truncate pixel-font">{item.name}</h2>
              </div>
              <div className="p-3 text-sm">
                <p className="mb-1 flex justify-between"><span className={`poppins-font font-medium ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}>Account:</span> <span className={isDarkMode ? 'text-gray-400 poppins-font' : 'text-gray-600 poppins-font'}>{item.account}</span></p>
                <p className="mb-1 flex justify-between"><span className={`poppins-font font-medium ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}>Latest Video:</span> <span className={isDarkMode ? 'text-gray-400 poppins-font' : 'text-gray-600 poppins-font'}>{new Date(item.latestVideo).toLocaleString()}</span></p>
                <p className="mb-1 flex justify-between"><span className={`poppins-font font-medium ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}>Backup Size:</span> <span className={isDarkMode ? 'text-gray-400 poppins-font' : 'text-gray-600 poppins-font'}>{item.backupSizeMB} MB</span></p>
                <p className="mb-1 flex justify-between"><span className={`poppins-font font-medium ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}>Videos:</span> <span className={isDarkMode ? 'text-gray-400 poppins-font' : 'text-gray-600 poppins-font'}>{item.videoCount}</span></p>
                <p className="mb-1 flex justify-between">
                  <span className={`poppins-font font-medium ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`} title="Average is calculated by record date, not release date. (Meta)">
                    Daily Video Avg.
                  </span>
                  <span className={isDarkMode ? 'text-gray-400 poppins-font' : 'text-gray-600 poppins-font'}>{item.averageVideoCountDaily}</span>
                </p>
                <p className="mb-1 flex justify-between"><span className={`poppins-font font-medium ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}>Last Scan:</span> <span className={isDarkMode ? 'text-gray-400 poppins-font' : 'text-gray-600 poppins-font'}>{new Date(item.lastScan).toLocaleString()}</span></p>
                <p className="mb-1 flex justify-between"><span className={`poppins-font font-medium ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}>Status:</span> <span className={isDarkMode ? 'text-gray-400 poppins-font' : 'text-gray-600 poppins-font'}><FontAwesomeIcon icon={faShieldAlt} /></span></p>
              </div>
              <div className={`p-3 text-right ${isDarkMode ? 'bg-gray-700' : 'bg-gray-50'}`}>
                <a
                  href={item.download}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={`px-3 py-1 rounded text-sm font-medium transition-colors duration-300 ${item.download ? 'bg-green-500 text-white hover:bg-green-600' : 'bg-gray-300 text-gray-500 cursor-not-allowed'}`}
                  onClick={(e) => !item.download && e.preventDefault()}
                >
                  Download
                </a>
              </div>

            </motion.div>
          ))}
        </div>
      </main>
    </div>
  );
}

export default App;